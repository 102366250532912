import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
// import axios from 'axios';
// 
// import Select from 'react-select';
import _ from 'lodash';
import ReactTable from 'react-table'
import 'react-table/react-table.css'


const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
];

const apiURL = "https://api.buddy.hr/v1";
// const apiURL = "https://7xdeph1wfb.execute-api.eu-central-1.amazonaws.com/dev";

const UsagePerCompany = () => {

  const [isAdmin, setIsAdmin] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [complete, setComplete] = useState(false);
  const [page, setPage] = useState(1);
  const [companyData, setCompanyData] = useState([]);

  useEffect(() => {

    if (!loaded){
      // set loaded right away to avoid a re-trigger
      fetch(apiURL + '/admin/isAdmin' , {
        // fetch('http://localhost:3000/report/templates' , {
          credentials: 'include',
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
      })
      .then(response => response.json())
      .then(data => {
        // obtained data
        console.log('isAdmin',data.isAdmin)
        setIsAdmin(data.isAdmin)

        if (!data.isAdmin){
          setLoaded(true)
        }
      })
    }
  },[]);

  useEffect(() => {

    if (isAdmin){
      // set loaded right away to avoid a re-trigger
      fetch(apiURL + `/admin/creditUsageReport/${page}` , {
          credentials: 'include',
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
      })
      .then(response => response.json())
      .then(data => {
        // obtained data
        setLoaded(true)
        // console.log('data CompanyData',data)

        setCompanyData(companyData.concat(data.data))

        if (data.data.length == 100){
          setPage(page+1);
        } else {
          setComplete(true)
        }
      })
    }
  },
  [isAdmin,page]
  );

  if (!loaded){
    return (
      <div>
        <h1>Loading</h1>
      </div>
    )
  }

  if (!isAdmin){
    return (
      <div>
        <h1>Access Denied</h1>
      </div>
    )
  }

  const columns = [{
    Header: 'Name',
    accessor: 'companyName' // String-based value accessors!
  }, {
    Header: 'Owner/Advisor',
    id: 'Owner/Advisor',
    // accessor: 'age',
    accessor: row => {
      if (row.advisorPhone){
        return `${row.advisorName} ${row.advisorSurname}`;
      } else {
        return `${row.ownerName} ${row.ownerSurname}`;
      }
    },
    Cell: props => {
      if (props.original.advisorName){
        return <span className='number'>{props.original.advisorName} {props.original.advisorSurname}</span>  
      } else {
        return <span className='number'>{props.original.ownerName} {props.original.ownerSurname}</span> // Custom cell components!
      }
    }
  }, {
    Header: 'Email',
    id: 'Email',
    // accessor: 'age',
    accessor: row => {
      if (row.advisorPhone){
        return row.advisorEmail;
      } else {
        return row.ownerEmail;
      }
    },
    Cell: props => {
      if (props.original.advisorEmail){
        return <span className='number'>{props.original.advisorEmail}</span>  
      } else {
        return <span className='number'>{props.original.ownerEmail}</span> // Custom cell components!
      }
    }
  }, {
    Header: 'Phone',
    id: 'Phone',
    // accessor: 'age',
    accessor: row => {
      if (row.advisorPhone){
        return row.advisorPhone;
      } else {
        return row.ownerPhone;
      }
    },
    Cell: props => {
      if (props.original.advisorPhone){
        return <span className='number'>{props.original.advisorPhone}</span>  
      } else {
        return <span className='number'>{props.original.ownerPhone}</span> // Custom cell components!
      }
    }
  }, {
    id: 'Employees', // Required because our accessor is not a string
    Header: 'Employees',
    accessor: "employees"
  }];


  // for the last 12 months add to show history.
  for (var i = 11; i >= 0 ; i--) {
    const d = new Date();
    d.setDate('1')
    d.setMonth(d.getMonth() - i);

    const month = d.toLocaleString('default', { month: 'short' });
    const year = d.getFullYear();
    const monthNo = d.getMonth() + 1

    columns.push({
      Header: `${month} ${year}`,
      id: `${month} ${year}`,
      accessor: row => {
        const payslipData =  _.find(row.payslips,{year,month:monthNo});
        return payslipData ? payslipData.paidPayslips : 0;
      }
    })
  }
 
  return (
    
    <div>
      <ReactTable
        data={companyData}
        columns={columns}
      />

      {!complete && <div>hang on loading page {page}...</div>}
      {complete && <div>Loading complete</div>}
    </div>
    
  );
}

export default UsagePerCompany;
