import React from 'react';
import logo from './logo.svg';
import './App.css';

import UsagePerCompany from './components/UsagePerCompany';

function App() {
  return (
    <div className="App">
      <UsagePerCompany/>
    </div>
  );
}

export default App;
